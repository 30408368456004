import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { DefaultGtag } from '../../utils/misc'
import '../../styles/Article.css'


const mainStyles = {
  display: "flex",
  flexDirection: "column"
}

const ArticlePage = () => {

  useEffect(() => {
    setTimeout(function () {
      // need delay, otherwise not working on firefox...
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, 100);
  }, [])

  return (
    <Layout>
      <main style={mainStyles}>
        <div className="blogpage">
          <div className="blog">
            <h1 className="title">Top 5 benefits of using QR code menus instead of PDF menus</h1>
            <p>If you're looking for a way to streamline your restaurant menus and make them more user-friendly, then you should consider using QR code menus (also called online restaurant menus). Not only are they easier for guests to read than PDF, but they also offer a number of benefits you may not be aware of. Here are five of the top benefits of using QR code menus:</p>
            <h2>1. Update your restaurant menu at any time with one click</h2>
            <p>Updating a QR code menu is easier than ever. Have your prices changed? Or your daily special? Do you want to change your menu's design for a special event? You’re always one click away from updating your QR code menu.</p>
            <p>And what's even better? Because QR code menus can be displayed directly on your restaurant's website, your online restaurant menu will also be updated automatically!</p>
            <h2>2. Reduced mobile data usage</h2>
            <p>PDF are difficult files to optimize and often very large. In contrast, QR code menus can be up to 10 times smaller than PDFs, making them cheaper and faster to load for your customers.</p>
            <h2>3. Faster ordering and payment</h2>
            <p>With QR code menus, you can allow your customers to order and pay directly from their phones. This saves you and your staff time and money.</p>
            <p>Bonus: Orders can also be printed directly in the kitchen to streamline the ordering process even further.</p>
            <h2>4. Private feedback</h2>
            <p>Because QR code menus are web-based, a feedback button can easily be added. Feedback remains private and you can read it and take action. This maintains a positive and healthy feedback loop between you and the customers whose voices are heard.</p>
            <h2>5. Understand your customers</h2>
            <p>With QR code menus, your customers' cravings will no longer be a secret to you. You'll see the menu options your customers look at, the food categories they browse and the keywords they search for. This gives you a better idea of who your customers are and how to better serve them.</p>
            <p>With PDFs, this is much more complex to set up and, in most situations, simply impossible to do.</p>
            <h2>Conclusion</h2>
            <p>To summarize, QR code menus are becoming a powerful weapon for restaurants to thrive. They adapt seemlessly to changes in your business, are faster and cheaper than PDF menus, and will alway wow customers.</p>
            <br />
            <br />
            <h2>About the author</h2>
            <p>This article was written by the team at Take a Seat, the digital partner of restaurants.</p>
            <p>We work to keep our articles short and to the point. If you would like more information, see menu examples, or need help setting up your QR code menus, feel free to contact our team at <a rel="nofollow" href="mailto:team@takeaseat.io" style={{ color: "blue" }}>team@takeaseat.io</a>. </p>
            <p style={{ paddingBottom: "3rem" }}>To create your restaurant's menu with us, visit our website at <a rel="author" href="https://takeaseat.io" style={{ color: "blue" }}>takeaseat.io</a>, it takes only 5 minutes from start to finish!</p>
          </div>
        </div>
      </main>
    </Layout >
  )
}

export default ArticlePage

export const Head = () => <>
  <DefaultGtag />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
  <meta property="og:site_name" content="Blog of Take a Seat" />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
  <meta name="twitter:site" content="@Takeaseat_io" />
  <meta name="twitter:creator" content="@Takeaseat_io" />
  <link rel="canonical" href="https://takeaseat.io/article/qr-code-vs-pdf-menu/" />
  {/* <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/article/qr-code-vs-pdf-menu/" />
  <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/article/qr-code-vs-pdf-menu/" />
  <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/article/qr-code-vs-pdf-menu/" />
  <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/article/qr-code-vs-pdf-menu/" /> */}

  <title>Top 5 benefits of using QR code menus instead of PDF menus for your restaurant</title>
  <meta name="description" content="If you're looking for a way to streamline your restaurant menus and make them more user-friendly, then you should consider using QR code menus, also called online restaurant menus." />
  <meta property="og:title" content="Top 5 benefits of using QR code menus instead of PDF menus for your restaurant" />
  <meta property="og:description" content="If you're looking for a way to streamline your restaurant menus and make them more user-friendly, then you should consider using QR code menus, also called online restaurant menus." />
  <meta property="og:url" content="https://takeaseat.io/article/qr-code-vs-pdf-menu/" />

  <meta name="twitter:title" content="Top 5 benefits of using QR code menus instead of PDF menus for your restaurant" />
  <meta name="twitter:description" content="If you're looking for a way to streamline your restaurant menus and make them more user-friendly, then you should consider using QR code menus, also called online restaurant menus." />

  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "headline": "Top 5 benefits of using QR code menus instead of PDF menus for your restaurant",
      "author": "Take a Seat",
      "description": "If you're looking for a way to streamline your restaurant menus and make them more user-friendly, then you should consider using QR code menus, also called online restaurant menus.",
      "url": "https://takeaseat.io/article/qr-code-vs-pdf-menu/",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://takeaseat.io/article/qr-code-vs-pdf-menu/"
      },
      "datePublished": "2022-09-30",
      "dateModified": "2022-09-30",
      "publisher": {
        "@type": "Organization",
        "name": "Take a Seat",
        "logo": {
          "@type": "ImageObject",
          "url": "https://takeaseat.io/media/icons/tas-230427.svg"
        }
      },
      "image": {
        "@type": "ImageObject",
        "url": "https://takeaseat.io/static/media/images/scan-qrcode-2.jpg"
      }
    })}
  </script>
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","index", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;